import { getBrandOrDefaultForStockType } from "./catalog";
import { getAllYards } from "./farmyardMetadata";

export interface FarmObjectDictionary {
    m_keys: number[]
    m_values: FarmObject[]
}

export interface IVector3 {
    x: number
    y: number
    z: number
}

export interface IVector2 {
    x: number;
    y: number;
}

export interface IQuaternion {
    x: number
    y: number
    z: number
    w: number
}

export interface FarmObject {
    position: IVector3
    rotation: IQuaternion
    scale: IVector3
    drawingNumber: string;
    revision: number;

    id: number,
    isSelected: boolean,
    LockState: number,
    _productCatagory: number,
    _templateName: string,
    snapJoints: SnapJoint[],
    brand: string | null
}

export interface SnapJoint {
    snapJointTemplate: {
        localPosition: IVector3
        radius: number,
        lineWidth: number
    }
    myID: number
    anchorID: number
}

export interface FarmYard {
    farmObjectDictionary: FarmObjectDictionary
    fileName: string
    catagoryType: number
    saveFileFormat: number
    cameraPositionX: number
    cameraPositionY: number
    cameraZoom: number
    brand: string
    stockType: string
}

export async function loadFarmYard(name: string): Promise<FarmYard> {
    let allYards = await getAllYards();
    let actualYard = allYards.find(y => y.name === name) || allYards[0];

    let farmYardResponse = await fetch(`farmyards/${actualYard.filename}`);
    let farmYard = await farmYardResponse.json();

    farmYard.stockType = actualYard.stockType;
    farmYard.brand = getBrandOrDefaultForStockType(actualYard.brand, actualYard.stockType);

    console.log("Loaded " + name, farmYard);
    return farmYard;
}
